var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "structure-panel" },
    [
      _c("StructureViewerTooltip", { attrs: { attach: ".structure-panel" } }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "structurepanel", staticClass: "structure-wrapper" },
        [
          _c("StructureViewerToolbar", {
            staticStyle: { position: "absolute", bottom: "8px" },
            attrs: {
              isFullscreen: _vm.isFullscreen,
              isSpinning: _vm.isSpinning,
              disableArrowButton: "",
              disableQueryButton: "",
              disableTargetButton: "",
            },
            on: {
              makeImage: _vm.handleMakeImage,
              makePDB: _vm.handleMakePDB,
              resetView: _vm.handleResetView,
              toggleFullscreen: _vm.handleToggleFullscreen,
              toggleSpin: _vm.handleToggleSpin,
            },
          }),
          _vm._v(" "),
          _c("div", { ref: "viewport", staticClass: "structure-viewer" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }