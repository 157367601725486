var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload-container w-44 gr-2 mb-2" }, [
    _c(
      "div",
      {
        staticClass: "upload-drag-area dotted-border gr-4",
        class: { "dotted-border-hover": _vm.inFileDrag },
        on: {
          click: _vm.triggerFilePicker,
          drop: _vm.handleDrop,
          dragover: _vm.handleDragOver,
          dragleave: _vm.handleDragLeave,
        },
      },
      [
        _c(
          "v-btn",
          {
            staticStyle: { "margin-bottom": "10px" },
            attrs: { outlined: "", large: "" },
          },
          [
            _c("v-icon", { attrs: { size: "30", color: "blue-accent-4" } }, [
              _vm._v(_vm._s(_vm.$MDI.FileUpload)),
            ]),
            _vm._v("\n            Click to select files\n        "),
          ],
          1
        ),
        _vm._v(" "),
        _c("p", { staticClass: "text-body-2" }, [_vm._v(_vm._s(_vm.bodyText))]),
      ],
      1
    ),
    _vm._v(" "),
    _c("input", {
      ref: "fileInput",
      staticClass: "hidden-button",
      attrs: { type: "file", multiple: _vm.multiple, accept: _vm.accept },
      on: { change: _vm.handleUpload },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }