<template>
<v-tooltip open-delay="300" top :attach="attach" nudge-left="25" background-color="transparent">
    <template v-slot:activator="{ on }">
        <v-icon :light="isFullscreen" v-on="on" style="position: absolute; z-index: 999; right: 0;">{{ $MDI.HelpCircleOutline }}</v-icon>
    </template>
    <span>
        <dl style="text-align: center;">
            <dt>
<svg v-bind="svgProps">
<title>Left click</title>
<path d="M25.6 5.8a5 5 0 0 0-5-4.8h-9.1a5 5 0 0 0-5.1 4.8v20.4a5 5 0 0 0 5 4.8h9.1a5 5 0 0 0 5.1-4.8V5.8Zm-1 9.5v10.9a4 4 0 0 1-4 3.8h-9.1a4 4 0 0 1-4-3.8V15.3h17ZM15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Zm1 0h4a4 4 0 0 1 4 3.8v8.5h-8V2Z"/>
<path id="left" d="M15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Z" style="fill:red"/>
<path id="middle-inactive" d="M14.6 4h2.8v8h-2.8z"/>
</svg>
            </dt>
            <dd>
                Rotate
            </dd>
            <dt>
<svg v-bind="svgProps">
<title>Right click</title>
<path d="M25.6 5.8a5 5 0 0 0-5-4.8h-9.1a5 5 0 0 0-5.1 4.8v20.4a5 5 0 0 0 5 4.8h9.1a5 5 0 0 0 5.1-4.8V5.8Zm-1 9.5v10.9a4 4 0 0 1-4 3.8h-9.1a4 4 0 0 1-4-3.8V15.3h17ZM15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Zm1 0h4a4 4 0 0 1 4 3.8v8.5h-8V2Z"/>
<path id="right" d="M16.5 2h4a4 4 0 0 1 4 3.8v8.5h-8V2Z" style="fill:red"/>
<path id="middle-inactive" d="M14.6 4h2.8v8h-2.8z"/>
</svg>
            </dt>
            <dd>
                Pan
            </dd>
            <dt>
<svg v-bind="svgProps">
<title>Scroll wheel</title>
<path d="M25.6 5.8a5 5 0 0 0-5-4.8h-9.1a5 5 0 0 0-5.1 4.8v20.4a5 5 0 0 0 5 4.8h9.1a5 5 0 0 0 5.1-4.8V5.8Zm-1 9.5v10.9a4 4 0 0 1-4 3.8h-9.1a4 4 0 0 1-4-3.8V15.3h17ZM15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Zm1 0h4a4 4 0 0 1 4 3.8v8.5h-8V2Z"/>
<path id="middle-active" d="M14.6 4h2.8v8h-2.8z" style="fill:red"/>
</svg>
            </dt>
            <dd>
                Zoom
            </dd>
        </dl>
    </span>
</v-tooltip>
</template>

<script>
export default {
    props: {
        attach: { type: String, required: true },
        isFullscreen: { type: Boolean, default: false }
    },
    computed: {
        svgProps: function() {
            return {
                xmlns: "http://www.w3.org/2000/svg",
                'xml:space': "preserve",
                style: "fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2",
                viewBox: "0 0 32 32"
            }
        }
    }
}
</script>