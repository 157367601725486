var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.multi
        ? _c(
            "v-list-group",
            {
              attrs: {
                "no-action": "",
                ripple: false,
                "prepend-icon": _vm.$MDI.FormatListBulleted,
              },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "template",
                { slot: "activator" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          "\n                    Queries\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.drawer
                        ? _c(
                            "v-list-item-subtitle",
                            {
                              staticClass: "ml-n1",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  style: { opacity: _vm.page == 0 ? 0.6 : 1 },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.previous()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticStyle: { transform: "inherit" } },
                                    [_vm._v(_vm._s(_vm.$MDI.ChevronLeft))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  style: {
                                    opacity: _vm.hasNext == false ? 0.6 : 1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.next()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticStyle: { transform: "inherit" } },
                                    [_vm._v(_vm._s(_vm.$MDI.ChevronRight))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.items.length > 0
                ? _vm._l(_vm.items, function (child, i) {
                    return _c(
                      "v-list-item",
                      {
                        key: child.name,
                        class: {
                          "list__item--active":
                            (_vm.groupBySet ? child.set : child.id) ==
                            _vm.entry,
                        },
                        staticStyle: { "padding-left": "16px" },
                        attrs: {
                          to: {
                            name: "result",
                            params: {
                              ticket: _vm.ticket,
                              entry: _vm.groupBySet ? child.set : child.id,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            (_vm.groupBySet ? child.set : child.id) == _vm.entry
                              ? _c("v-icon", [_vm._v(_vm._s(_vm.$MDI.Label))])
                              : _c("v-icon", [
                                  _vm._v(_vm._s(_vm.$MDI.LabelOutline)),
                                ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(child.name) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }