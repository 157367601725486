var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items && _vm.items.length > 0
    ? _c(
        "v-list-group",
        {
          attrs: { "no-action": "", "prepend-icon": _vm.$MDI.History },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "template",
            { slot: "activator" },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v("\n                History\n            "),
                  ]),
                  _vm._v(" "),
                  _vm.drawer
                    ? _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "ml-n1",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              style: { opacity: _vm.page == 0 ? 0.6 : 1 },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.previous()
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticStyle: { transform: "inherit" } },
                                [_vm._v(_vm._s(_vm.$MDI.ChevronLeft))]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              style: {
                                opacity:
                                  (_vm.page + 1) * _vm.limit >= _vm.items.length
                                    ? 0.6
                                    : 1,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.next()
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticStyle: { transform: "inherit" } },
                                [_vm._v(_vm._s(_vm.$MDI.ChevronRight))]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(
            _vm.items.slice(_vm.page * _vm.limit, (_vm.page + 1) * _vm.limit),
            function (child, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  class: { "list__item--highlighted": child.id == _vm.current },
                  staticStyle: { "padding-left": "16px" },
                  attrs: { to: _vm.formattedRoute(child) },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      child.status == "COMPLETE"
                        ? _c("identicon", { attrs: { hash: child.id } })
                        : child.status == "RUNNING"
                        ? _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v(_vm._s(_vm.$MDI.ClockOutline)),
                          ])
                        : child.status == "PENDING"
                        ? _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v(_vm._s(_vm.$MDI.ClockOutline)),
                          ])
                        : child.status == "ERROR"
                        ? _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v(_vm._s(_vm.$MDI.HelpCircleOutline)),
                          ])
                        : _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v(_vm._s(_vm.$MDI.HelpCircleOutline)),
                          ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.formattedDate(child.time)) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _c("span", { staticClass: "mono" }, [
                          _vm._v(_vm._s(child.id)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }