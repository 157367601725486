var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.alignments.length > 0 && "tCa" in _vm.alignments[0]
    ? _c(
        "div",
        { staticClass: "structure-panel" },
        [
          _c("StructureViewerTooltip", {
            attrs: { attach: ".structure-panel" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { ref: "structurepanel", staticClass: "structure-wrapper" },
            [
              _vm.tmAlignResults
                ? _c(
                    "table",
                    _vm._b(
                      { staticClass: "tmscore-panel" },
                      "table",
                      _vm.tmPanelBindings,
                      false
                    ),
                    [
                      _c("tr", [
                        _c("td", { staticClass: "left-cell" }, [
                          _vm._v("TM-Score:"),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "right-cell" }, [
                          _vm._v(_vm._s(_vm.tmAlignResults.tmScore)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "left-cell" }, [
                          _vm._v("RMSD:"),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "right-cell" }, [
                          _vm._v(_vm._s(_vm.tmAlignResults.rmsd)),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("StructureViewerToolbar", {
                attrs: {
                  isFullscreen: _vm.isFullscreen,
                  isSpinning: _vm.isSpinning,
                  showQuery: _vm.showQuery,
                  showTarget: _vm.showTarget,
                  showArrows: _vm.showArrows,
                  disableQueryButton: !_vm.hasQuery,
                  disableArrowButton: !_vm.hasQuery,
                },
                on: {
                  makeImage: _vm.handleMakeImage,
                  makePDB: _vm.handleMakePDB,
                  resetView: _vm.handleResetView,
                  toggleFullscreen: _vm.handleToggleFullscreen,
                  toggleTarget: _vm.handleToggleTarget,
                  toggleQuery: _vm.handleToggleQuery,
                  toggleArrows: _vm.handleToggleArrows,
                  toggleSpin: _vm.handleToggleSpin,
                },
              }),
              _vm._v(" "),
              _c("div", { ref: "viewport", staticClass: "structure-viewer" }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }