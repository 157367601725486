var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.status != "COMPLETE"
    ? _c(
        "v-container",
        { attrs: { "grid-list-md": "", fluid: "", "pa-2": "" } },
        [
          _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm10: "" } },
                [
                  _c(
                    "panel",
                    [
                      _c("template", { slot: "header" }, [
                        _vm._v(
                          "\n                    Job Status: \n                    "
                        ),
                        _vm.status == "PENDING"
                          ? _c("strong", [_vm._v("Waiting for Worker")])
                          : _vm.status == "RUNNING"
                          ? _c("strong", [_vm._v("In Progress")])
                          : _c("strong", [_vm._v("ERROR")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-container",
                        {
                          attrs: {
                            slot: "content",
                            "grid-list-xs": "",
                            fluid: "",
                          },
                          slot: "content",
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "status-img",
                                  attrs: {
                                    xs12: "",
                                    sm6: "",
                                    md4: "",
                                    "aria-hidden": "true",
                                  },
                                },
                                [
                                  _vm.status == "PENDING"
                                    ? _c("img", {
                                        attrs: {
                                          src: require("./assets/marv-search_2x.png"),
                                          srcset:
                                            require("./assets/marv-search_2x.png") +
                                            " 2x, " +
                                            require("./assets/marv-search_3x.png") +
                                            " 3x",
                                        },
                                      })
                                    : _vm.status == "RUNNING"
                                    ? _c("img", {
                                        attrs: {
                                          src: require("./assets/marv-result_2x.png"),
                                          srcset:
                                            require("./assets/marv-result_2x.png") +
                                            " 2x, " +
                                            require("./assets/marv-result_3x.png") +
                                            " 3x",
                                        },
                                      })
                                    : _vm.status == "COMPLETE"
                                    ? _c("div")
                                    : _c("img", {
                                        attrs: {
                                          src: require("./assets/marv-error_2x.png"),
                                          srcset:
                                            require("./assets/marv-error_2x.png") +
                                            " 2x, " +
                                            require("./assets/marv-error_3x.png") +
                                            " 3x",
                                        },
                                      }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", md8: "" } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.error) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }