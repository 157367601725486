var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", fluid: "", "pa-2": "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "panel",
                { staticClass: "msa-panel" },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      !_vm.$LOCAL && !_vm.msaData
                        ? [
                            _c("span", { staticClass: "hidden-sm-and-down" }, [
                              _vm._v("Results for job: "),
                            ]),
                            _vm._v(" "),
                            _c("small", { staticClass: "ticket" }, [
                              _vm._v(_vm._s(_vm.ticket)),
                            ]),
                          ]
                        : [
                            _c("span", { staticClass: "hidden-sm-and-down" }, [
                              _vm._v("Results: "),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.$LOCAL && _vm.resultState == "PENDING"
                    ? _c(
                        "div",
                        { attrs: { slot: "desc" }, slot: "desc" },
                        [
                          _c(
                            "v-container",
                            {
                              attrs: { "fill-height": "", "grid-list-md": "" },
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("img", {
                                      staticStyle: { "max-width": "100%" },
                                      attrs: {
                                        src: require("./assets/marv-search_2x.png"),
                                        srcset:
                                          require("./assets/marv-search_2x.png") +
                                          " 2x, " +
                                          require("./assets/marv-search_3x.png") +
                                          " 3x",
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _c("h3", [_vm._v("Still Pending")]),
                                    _vm._v(" "),
                                    _c("p", [_vm._v("Please wait a moment")]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : !_vm.$LOCAL && _vm.resultState == "EMPTY"
                    ? _c(
                        "div",
                        { attrs: { slot: "desc" }, slot: "desc" },
                        [
                          _c(
                            "v-container",
                            {
                              attrs: { "fill-height": "", "grid-list-md": "" },
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("img", {
                                      staticStyle: { "max-width": "100%" },
                                      attrs: {
                                        src: require("./assets/marv-result_2x.png"),
                                        srcset:
                                          require("./assets/marv-result_2x.png") +
                                          " 2x, " +
                                          require("./assets/marv-result_3x.png") +
                                          " 3x",
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _c("h3", [_vm._v("No hits found!")]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      [
                                        _vm._v("Start a "),
                                        _c(
                                          "v-btn",
                                          { attrs: { to: "/search" } },
                                          [_vm._v("New Alignment")]
                                        ),
                                        _vm._v("?"),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : !_vm.$LOCAL && _vm.resultState != "RESULT"
                    ? _c(
                        "div",
                        { attrs: { slot: "desc" }, slot: "desc" },
                        [
                          _c(
                            "v-container",
                            {
                              attrs: { "fill-height": "", "grid-list-md": "" },
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("img", {
                                      staticStyle: { "max-width": "100%" },
                                      attrs: {
                                        src: require("./assets/marv-error_2x.png"),
                                        srcset:
                                          require("./assets/marv-error_2x.png") +
                                          " 2x, " +
                                          require("./assets/marv-error_3x.png") +
                                          " 3x",
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _c("h3", [_vm._v("Error! ")]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      [
                                        _vm._v("Start a "),
                                        _c(
                                          "v-btn",
                                          { attrs: { to: "/foldmason" } },
                                          [_vm._v("New Alignment")]
                                        ),
                                        _vm._v("?"),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.resultState == "RESULT" && _vm.msaData
                    ? _c(
                        "template",
                        { slot: "content" },
                        [
                          _vm.msaData
                            ? _c("MSA", {
                                attrs: {
                                  entries: _vm.msaData.entries,
                                  scores: _vm.msaData.scores,
                                  statistics: _vm.msaData.statistics,
                                  tree: _vm.msaData.tree,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }