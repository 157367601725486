var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { "open-delay": "300", top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c("v-autocomplete", {
                attrs: {
                  value: _vm.value,
                  label: "Taxonomic filter",
                  placeholder: "Start typing scientific name to search",
                  "hide-no-data": "",
                  items: _vm.items,
                  loading: _vm.isLoading,
                  "search-input": _vm.search,
                  "return-object": "",
                  "auto-select-first": "",
                  "allow-overflow": false,
                  dense: "",
                  chips: "",
                  "deletable-chips": "",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                  input: _vm.change,
                },
              }),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v("Restrict results to taxonomic clade")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }