var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            { attrs: { rounded: "0" } },
            [
              _c(
                "v-card-title",
                { staticClass: "pb-0 mb-0", attrs: { "primary-title": "" } },
                [
                  _c("div", { staticClass: "text-h5 mb-0" }, [
                    _vm._v("Reference"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-card-title",
                { staticClass: "pt-0 mt-0", attrs: { "primary-title": "" } },
                [
                  _c("p", {
                    staticClass: "text-subtitle-2 mb-0",
                    domProps: { innerHTML: _vm._s(_vm.reference) },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }