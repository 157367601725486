var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-textarea", {
    staticClass: "marv-bg mono",
    attrs: {
      "aria-label": _vm.$STRINGS.QUERIES_HELP,
      loading: _vm.loading,
      value: _vm.value,
      placeholder: _vm.$STRINGS.QUERIES_HELP,
      spellcheck: "false",
      "data-gramm": "false",
      "data-gramm_editor": "false",
      "data-enable-grammarly": "false",
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
      dragover: function ($event) {
        $event.preventDefault()
      },
      drop: _vm.fileDrop,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }