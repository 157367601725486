var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-dialog", {
    attrs: { disabled: _vm.disabled, label: "API", icon: _vm.$MDI.API },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v("cURL Command")]
        },
        proxy: true,
      },
      {
        key: "text",
        fn: function () {
          return [
            _vm._v(
              "\n        " + _vm._s(_vm.$STRINGS.CURL_INTRO) + "\n        "
            ),
            _c("br"),
            _vm._v(" "),
            _c(
              "code",
              [
                _vm._v("curl -X POST -F q=@PATH_TO_FILE "),
                _vm.email
                  ? _c("span", [_vm._v("-F 'email=" + _vm._s(_vm.email) + "'")])
                  : _vm._e(),
                _vm._v(" -F 'mode=" + _vm._s(_vm.mode) + "' "),
                _vm.taxfilter
                  ? _c("span", [
                      _vm._v("-F 'taxfilter=" + _vm._s(_vm.taxfilter) + "'"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.database, function (path, i) {
                  return _c("span", { key: i }, [
                    _vm._v("-F 'database[]=" + _vm._s(path) + "' "),
                  ])
                }),
                _vm._v(" " + _vm._s(_vm.origin() + "/api/ticket")),
              ],
              2
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n        Refer to the "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://search.mmseqs.com/docs/",
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v("API documentation")]
            ),
            _vm._v(", on how to check the status and fetch the result.\n    "),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showCurl,
      callback: function ($$v) {
        _vm.showCurl = $$v
      },
      expression: "showCurl",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }