var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { absolute: "", disabled: _vm.disabled },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { plain: "", disabled: _vm.disabled } }, on),
                [
                  _vm.icon
                    ? _c("v-icon", [_vm._v(_vm._s(_vm.icon))])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.label) + "\n    "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("div", { staticClass: "text-h5" }, [_vm._t("title")], 2),
          ]),
          _vm._v(" "),
          _c("v-card-text", [_vm._t("text")], 2),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }