var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "toolbar-panel" },
    [
      _c(
        "v-item-group",
        { staticClass: "v-btn-toggle", attrs: { light: _vm.isFullscreen } },
        [
          !_vm.disablePDBButton
            ? _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { title: "Save PDB" },
                    on: { click: _vm.handleClickMakePDB },
                  },
                  "v-btn",
                  _vm.toolbarButtonProps,
                  false
                ),
                [
                  _c(
                    "v-icon",
                    _vm._b({}, "v-icon", _vm.toolbarIconProps, false),
                    [_vm._v(_vm._s(_vm.$MDI.SavePDB))]
                  ),
                  _vm._v(" "),
                  _vm.isFullscreen
                    ? _c("span", [_vm._v(" Save PDB")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disableImageButton
            ? _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { title: "Save image" },
                    on: { click: _vm.handleClickMakeImage },
                  },
                  "v-btn",
                  _vm.toolbarButtonProps,
                  false
                ),
                [
                  _c(
                    "v-icon",
                    _vm._b({}, "v-icon", _vm.toolbarIconProps, false),
                    [_vm._v(_vm._s(_vm.$MDI.SavePNG))]
                  ),
                  _vm._v(" "),
                  _vm.isFullscreen
                    ? _c("span", [_vm._v(" Save image")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disableQueryButton
            ? _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: {
                      title:
                        "Toggle between the entire query structure and aligned region",
                    },
                    on: { click: _vm.handleClickCycleQuery },
                  },
                  "v-btn",
                  _vm.toolbarButtonProps,
                  false
                ),
                [
                  _vm.showQuery === 0
                    ? _c(
                        "v-icon",
                        _vm._b(
                          { staticStyle: { color: "#1E88E5" } },
                          "v-icon",
                          _vm.toolbarIconProps,
                          false
                        ),
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$LOCAL
                                ? _vm.$MDI.CircleHalf
                                : _vm.$MDI.CircleOneThird
                            )
                          ),
                        ]
                      )
                    : !_vm.$LOCAL && _vm.showQuery === 1
                    ? _c(
                        "v-icon",
                        _vm._b(
                          { staticStyle: { color: "#1E88E5" } },
                          "v-icon",
                          _vm.toolbarIconProps,
                          false
                        ),
                        [_vm._v(_vm._s(_vm.$MDI.CircleTwoThird))]
                      )
                    : _c(
                        "v-icon",
                        _vm._b(
                          { staticStyle: { color: "#1E88E5" } },
                          "v-icon",
                          _vm.toolbarIconProps,
                          false
                        ),
                        [_vm._v(_vm._s(_vm.$MDI.Circle))]
                      ),
                  _vm._v(" "),
                  _vm.isFullscreen
                    ? _c("span", [_vm._v(" Toggle full query")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disableTargetButton
            ? _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: {
                      title:
                        "Toggle between the entire target structure and aligned region",
                    },
                    on: { click: _vm.handleClickToggleTarget },
                  },
                  "v-btn",
                  _vm.toolbarButtonProps,
                  false
                ),
                [
                  _vm.showTarget === 0
                    ? _c(
                        "v-icon",
                        _vm._b(
                          { staticStyle: { color: "#FFC107" } },
                          "v-icon",
                          _vm.toolbarIconProps,
                          false
                        ),
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$LOCAL
                                ? _vm.$MDI.CircleHalf
                                : _vm.$MDI.CircleOneThird
                            )
                          ),
                        ]
                      )
                    : !_vm.$LOCAL && _vm.showTarget === 1
                    ? _c(
                        "v-icon",
                        _vm._b(
                          { staticStyle: { color: "#FFC107" } },
                          "v-icon",
                          _vm.toolbarIconProps,
                          false
                        ),
                        [_vm._v(_vm._s(_vm.$MDI.CircleTwoThird))]
                      )
                    : _c(
                        "v-icon",
                        _vm._b(
                          { staticStyle: { color: "#FFC107" } },
                          "v-icon",
                          _vm.toolbarIconProps,
                          false
                        ),
                        [_vm._v(_vm._s(_vm.$MDI.Circle))]
                      ),
                  _vm._v(" "),
                  _vm.isFullscreen
                    ? _c("span", [_vm._v(" Toggle full target")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disableArrowButton
            ? _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { title: "Draw arrows between aligned residues" },
                    on: { click: _vm.handleClickToggleArrows },
                  },
                  "v-btn",
                  _vm.toolbarButtonProps,
                  false
                ),
                [
                  _vm.showArrows
                    ? _c(
                        "v-icon",
                        _vm._b({}, "v-icon", _vm.toolbarIconProps, false),
                        [_vm._v(_vm._s(_vm.$MDI.ArrowRightCircle))]
                      )
                    : _c(
                        "v-icon",
                        _vm._b({}, "v-icon", _vm.toolbarIconProps, false),
                        [_vm._v(_vm._s(_vm.$MDI.ArrowRightCircleOutline))]
                      ),
                  _vm._v(" "),
                  _vm.isFullscreen
                    ? _c("span", [_vm._v(" Toggle arrows")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disableResetButton
            ? _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: {
                      title:
                        "Reset the view to the original position and zoom level",
                    },
                    on: { click: _vm.handleClickResetView },
                  },
                  "v-btn",
                  _vm.toolbarButtonProps,
                  false
                ),
                [
                  _c(
                    "v-icon",
                    _vm._b({}, "v-icon", _vm.toolbarIconProps, false),
                    [_vm._v(_vm._s(_vm.$MDI.Restore))]
                  ),
                  _vm._v(" "),
                  _vm.isFullscreen
                    ? _c("span", [_vm._v(" Reset view")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disableSpinButton
            ? _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: {
                      disabled: _vm.isSpinning,
                      title: "Toggle spinning structure",
                    },
                    on: { click: _vm.handleClickSpin },
                  },
                  "v-btn",
                  _vm.toolbarButtonProps,
                  false
                ),
                [
                  _c(
                    "v-icon",
                    _vm._b({}, "v-icon", _vm.toolbarIconProps, false),
                    [_vm._v(_vm._s(_vm.$MDI.AxisZRotateCounterclockwise))]
                  ),
                  _vm._v(" "),
                  _vm.isFullscreen
                    ? _c("span", [_vm._v(" Toggle spin")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disableFullscreenButton
            ? _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: {
                      title: "Enter fullscreen mode - press ESC to exit",
                    },
                    on: { click: _vm.handleClickFullscreen },
                  },
                  "v-btn",
                  _vm.toolbarButtonProps,
                  false
                ),
                [
                  _c(
                    "v-icon",
                    _vm._b({}, "v-icon", _vm.toolbarIconProps, false),
                    [_vm._v(_vm._s(_vm.$MDI.Fullscreen))]
                  ),
                  _vm._v(" "),
                  _vm.isFullscreen
                    ? _c("span", [_vm._v(" Fullscreen")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }