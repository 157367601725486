var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { class: { electron: _vm.$ELECTRON }, attrs: { id: "app" } },
    [_c("navigation"), _vm._v(" "), _c("v-main", [_c("router-view")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }