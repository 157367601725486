import { render, staticRenderFns } from "./Queries.vue?vue&type=template&id=e49f3952&"
import script from "./Queries.vue?vue&type=script&lang=js&"
export * from "./Queries.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VListGroup,VListItem,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/opt/mmseqs-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e49f3952')) {
      api.createRecord('e49f3952', component.options)
    } else {
      api.reload('e49f3952', component.options)
    }
    module.hot.accept("./Queries.vue?vue&type=template&id=e49f3952&", function () {
      api.rerender('e49f3952', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/Queries.vue"
export default component.exports