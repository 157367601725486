var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: {
        "open-delay": "300",
        top: "",
        attach: _vm.attach,
        "nudge-left": "25",
        "background-color": "transparent",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-icon",
                _vm._g(
                  {
                    staticStyle: {
                      position: "absolute",
                      "z-index": "999",
                      right: "0",
                    },
                    attrs: { light: _vm.isFullscreen },
                  },
                  on
                ),
                [_vm._v(_vm._s(_vm.$MDI.HelpCircleOutline))]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("span", [
        _c("dl", { staticStyle: { "text-align": "center" } }, [
          _c("dt", [
            _c("svg", _vm._b({}, "svg", _vm.svgProps, false), [
              _c("title", [_vm._v("Left click")]),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M25.6 5.8a5 5 0 0 0-5-4.8h-9.1a5 5 0 0 0-5.1 4.8v20.4a5 5 0 0 0 5 4.8h9.1a5 5 0 0 0 5.1-4.8V5.8Zm-1 9.5v10.9a4 4 0 0 1-4 3.8h-9.1a4 4 0 0 1-4-3.8V15.3h17ZM15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Zm1 0h4a4 4 0 0 1 4 3.8v8.5h-8V2Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "red" },
                attrs: {
                  id: "left",
                  d: "M15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: { id: "middle-inactive", d: "M14.6 4h2.8v8h-2.8z" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("dd", [_vm._v("\n                Rotate\n            ")]),
          _vm._v(" "),
          _c("dt", [
            _c("svg", _vm._b({}, "svg", _vm.svgProps, false), [
              _c("title", [_vm._v("Right click")]),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M25.6 5.8a5 5 0 0 0-5-4.8h-9.1a5 5 0 0 0-5.1 4.8v20.4a5 5 0 0 0 5 4.8h9.1a5 5 0 0 0 5.1-4.8V5.8Zm-1 9.5v10.9a4 4 0 0 1-4 3.8h-9.1a4 4 0 0 1-4-3.8V15.3h17ZM15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Zm1 0h4a4 4 0 0 1 4 3.8v8.5h-8V2Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "red" },
                attrs: {
                  id: "right",
                  d: "M16.5 2h4a4 4 0 0 1 4 3.8v8.5h-8V2Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: { id: "middle-inactive", d: "M14.6 4h2.8v8h-2.8z" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("dd", [_vm._v("\n                Pan\n            ")]),
          _vm._v(" "),
          _c("dt", [
            _c("svg", _vm._b({}, "svg", _vm.svgProps, false), [
              _c("title", [_vm._v("Scroll wheel")]),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M25.6 5.8a5 5 0 0 0-5-4.8h-9.1a5 5 0 0 0-5.1 4.8v20.4a5 5 0 0 0 5 4.8h9.1a5 5 0 0 0 5.1-4.8V5.8Zm-1 9.5v10.9a4 4 0 0 1-4 3.8h-9.1a4 4 0 0 1-4-3.8V15.3h17ZM15.5 2v12.3h-8V5.8a4 4 0 0 1 4-3.8h4Zm1 0h4a4 4 0 0 1 4 3.8v8.5h-8V2Z",
                },
              }),
              _vm._v(" "),
              _c("path", {
                staticStyle: { fill: "red" },
                attrs: { id: "middle-active", d: "M14.6 4h2.8v8h-2.8z" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("dd", [_vm._v("\n                Zoom\n            ")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }