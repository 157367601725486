var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "input-group" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { "open-delay": "300", top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _c(
                        "label",
                        _vm._g({}, on),
                        [
                          _vm._v("Databases "),
                          _c(
                            "v-icon",
                            _vm._g(
                              {
                                staticStyle: { "margin-top": "-3px" },
                                attrs: { color: "#FFFFFFB3", small: "" },
                              },
                              on
                            ),
                            [_vm._v(_vm._s(_vm.$MDI.HelpCircleOutline))]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _vm.$ELECTRON || _vm.hideEmail
                ? _c("span", [
                    _vm._v(
                      "Choose the databases to search against and the result mode."
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      "Choose the databases to search against, the result mode, and optionally an email to notify you\n                when the job is done."
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.allDatabases.length == 0
        ? _c("div", [
            _c(
              "div",
              {
                class: [
                  "alert",
                  { "alert-info": !_vm.dberror },
                  { "alert-danger": _vm.dberror },
                ],
              },
              [
                _vm.dberror
                  ? _c("span", [_vm._v("Could not query available databases!")])
                  : _vm.dbqueried == false
                  ? _c("span", [_vm._v("Loading databases...")])
                  : _c("span", [
                      _vm._v("\n                No databases found! "),
                      _c("br"),
                      _vm._v(" "),
                      _vm.$ELECTRON
                        ? _c(
                            "span",
                            [
                              _vm._v("\n                    Go to "),
                              _c(
                                "router-link",
                                { attrs: { to: "preferences" } },
                                [_vm._v("Preferences")]
                              ),
                              _vm._v(" to add a database.\n                "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
              ]
            ),
          ])
        : _vm._l(_vm.allDatabases, function (db, index) {
            return _c("v-checkbox", {
              key: index,
              attrs: {
                value: db.path,
                label: db.name + " " + db.version,
                "append-icon":
                  db.status == "ERROR" || db.status == "UNKNOWN"
                    ? _vm.$MDI.AlertCircleOutline
                    : db.status == "PENDING" || db.status == "RUNNING"
                    ? _vm.$MDI.ProgressWrench
                    : undefined,
                disabled: db.status != "COMPLETE",
                "hide-details": "",
              },
              model: {
                value: _vm.selectedDatabases,
                callback: function ($$v) {
                  _vm.selectedDatabases = $$v
                },
                expression: "selectedDatabases",
              },
            })
          }),
      _vm._v(" "),
      _vm.databasesNotReady
        ? _c("div", { staticClass: "alert alert-info mt-1" }, [
            _c("span", [_vm._v("Databases are loading...")]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }