var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "search-component",
      attrs: { "grid-list-md": "", fluid: "", "px-2": "", "py-1": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "panel",
                {
                  staticClass: "query-panel d-flex fill-height",
                  attrs: { "fill-height": "" },
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(
                      "\n            Input protein structures (PDB only) for MSTA\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "toolbar-extra" },
                    [
                      _vm.queries.length > 0
                        ? _c(
                            "v-icon",
                            {
                              staticStyle: { "margin-right": "16px" },
                              attrs: { title: "Clear" },
                              on: {
                                click: function ($event) {
                                  _vm.queries = []
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$MDI.Delete))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("template", { slot: "content" }, [
                    _c(
                      "div",
                      { staticClass: "upload-outer-container w-44 gr-2 mb-2" },
                      [
                        _c("DragUploadBox", {
                          staticClass: "drag-upload-box",
                          attrs: { multiple: "" },
                          on: { uploadedFiles: _vm.upload },
                        }),
                        _vm._v(" "),
                        _vm.queries.length > 0
                          ? _c(
                              "div",
                              { staticClass: "query-chip-group" },
                              _vm._l(_vm.queries, function (q, index) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: q.name,
                                    attrs: {
                                      close: "",
                                      outlined: "",
                                      value: q.name,
                                    },
                                    on: {
                                      "click:close": function ($event) {
                                        return _vm.removeQuery(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(q.name) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "actions input-buttons-panel" }, [
                      _c(
                        "div",
                        { staticClass: "input-buttons-left" },
                        [
                          _c("file-button", {
                            attrs: {
                              id: "localFile",
                              label: "Upload previous result (JSON)",
                            },
                            on: { upload: _vm.uploadJSON },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn btn--raised btn--file",
                              staticStyle: { position: "relative" },
                              attrs: { type: "button", color: "primary" },
                              on: { click: _vm.handleLoadExample },
                            },
                            [
                              _vm._v(
                                "\n                        Load example MSTA\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "panel",
                {
                  attrs: {
                    collapsible: "",
                    collapsed: "",
                    "render-collapsed": "",
                  },
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v("Alignment settings"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "toolbar-extra" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: { "margin-right": "16px" },
                          attrs: { title: "Reset to default parameters" },
                          on: { click: _vm.resetParams },
                        },
                        [
                          _vm._v(
                            "\n                " + _vm._s(_vm.$MDI.Restore)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { slot: "content" }, slot: "content" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          min: "0",
                          step: "1",
                          type: "number",
                          label: "Gap open penalty",
                          dense: "",
                        },
                        model: {
                          value: _vm.params.gapOpen,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "gapOpen", $$v)
                          },
                          expression: "params.gapOpen",
                        },
                      }),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          min: "0",
                          step: "1",
                          type: "number",
                          label: "Gap extension penalty",
                          dense: "",
                        },
                        model: {
                          value: _vm.params.gapExtend,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "gapExtend", $$v)
                          },
                          expression: "params.gapExtend",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _c(
                "panel",
                [
                  _c("template", { slot: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "actions",
                        style: !_vm.$vuetify.breakpoint.xsOnly
                          ? "display:flex; align-items: center;"
                          : null,
                      },
                      [
                        _c(
                          "v-item-group",
                          { staticClass: "v-btn-toggle" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  block: false,
                                  "x-large": "",
                                  disabled: _vm.alignDisabled,
                                },
                                on: { click: _vm.search },
                              },
                              [
                                _c("v-icon", [_vm._v(_vm._s(_vm.$MDI.Wall))]),
                                _vm._v("\n                     Align"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            style: !_vm.$vuetify.breakpoint.xsOnly
                              ? "margin-left: 1em;"
                              : "margin-top: 1em;",
                          },
                          [
                            _c("span", [_c("strong", [_vm._v("Summary")])]),
                            _c("br"),
                            _vm._v("\n                Align "),
                            _c("strong", [_vm._v(_vm._s(_vm.queries.length))]),
                            _vm._v(
                              " structures with FoldMason\n                "
                            ),
                            _vm._v(" "),
                            _vm.errorMessage != ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-alert v-alert--outlined warning--text mt-2",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.errorMessage)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("reference", {
        attrs: { reference: _vm.$STRINGS.CITATION_FOLDMASON },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }